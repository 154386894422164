import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AreaChart, XAxis, YAxis, Tooltip, Area } from "recharts";
// import $axios from "utils/axios";
import Picker from "./Picker";
import moment from "moment";
import { commarize } from "utils/heplers";
interface Ichart {
  date: string;
  installs: number;
}

const ChartMyInstalls = (props: any): JSX.Element => {
  const { data, numberMonth, onUpdate } = props;
  const [installs, setInstalls] = useState<Ichart[]>([]);

  const [numMonth, setNumMonth] = useState<number>(numberMonth);

  const selectedPublisher = useSelector(
    (state) => state.publisherReducer.selectedPublisher
  );

  useEffect(() => {
    onUpdate(numMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numMonth]);

  useEffect(() => {
    (async function () {
      if (data) {
        setInstalls(data);

        let dataChart = Object.keys(data).map((e) => {
          return {
            date: moment(e).format("YY-MM"),
            installs: data[e].total_installs,
          };
        });
        setInstalls(dataChart);
        setNumMonth(numberMonth);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher.row_id, data]);

  // if (!installs) {
  //   return "";
  // }

  return (
    <>
      <Picker
        label="My Installs"
        defaultSelected={numMonth}
        onSelected={(d) => {
          setNumMonth(d);
        }}
      />
      <AreaChart
        width={730}
        height={250}
        data={installs}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorinstalls" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" />
        <YAxis tickFormatter={(v) => commarize(v)} />
        <Tooltip formatter={(v: number) => v.toLocaleString()} />

        <Area
          type="monotone"
          dataKey="installs"
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#colorinstalls)"
        />
      </AreaChart>
    </>
  );
};

export default ChartMyInstalls;
