import React, { useEffect, useState } from "react";
import { PublisherService } from "../../services/PublisherService";
import { publisherStatus } from "enums/Publisher";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Loading from "../Global/Loading";
import { useDispatch } from "react-redux";

type Iquestionnaire = {
  app_names: string;
  billing_contact: string;
  communication_method: string;
  type: number[];
  device_type: string;
  total_installs: string;
  unique_users: string;
  link: string;
  skype_username: string;
  phone: string;
  [key: string]: any;
};

const QuestionnaireModal = (props: {
  onFinish: any;
  show: any;
  size: any;
  onShowChange: any;
  publisher: any;
}) => {
  const { onFinish, show, size, onShowChange, publisher } = props;
  const [modal, setModal] = useState(show);
  const [showCommunicationMethodInput, setShowCommunicationMethodInput] =
    useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [questionnaire, setQuestionnaire] = useState<Iquestionnaire>({
    app_names: "",
    billing_contact: "",
    communication_method: "Email",
    type: [1],
    device_type: "",
    total_installs: "",
    unique_users: "",
    link: "",
    skype_username: "",
    phone: "",
  });

  const toggle = () => {
    onShowChange(!modal);
    setModal(!modal);
  };

  const save = async () => {
    setLoading(!loading);
    questionnaire.device_type = questionnaire.type.join(",");
    await PublisherService.publisherQuestionnair(publisher, questionnaire);
    dispatch({
      type: "UPDATE_SELECTED_PUBLISHER",
      publisher: { publisher_status: publisherStatus.PENDING },
    });
    onFinish(questionnaire);
    setLoading(!loading);
    setModal(!modal);
  };

  useEffect(() => {
    setModal(show);
  }, [show]);

  useEffect(() => {
    checkIsValid();
    setShowCommunicationMethodInput(
      !["Email", "Phone", "Skype"].includes(questionnaire.communication_method)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire]);

  const onChangeMulti = (event: any) => {
    let opts = [];
    let opt;
    for (let i = 0, len = event.target.options.length; i < len; i++) {
      opt = event.target.options[i];
      if (opt.selected) {
        opts.push(opt.value);
      }
    }
    changeQuestionnaire("device_type", opts);
  };

  const checkIsValid = () => {
    let valid = true;
    // for (let key in questionnaire) {
    //   console.log(questionnaire[key]);

    //   if (
    //     (questionnaire[key] == null || questionnaire[key] === "") &&
    //     questionnaire[key] !== "link" &&
    //     questionnaire[key] !== "skype_username" &&
    //     questionnaire[key] !== "phone"
    //   ) {
    //     valid = false;
    //   }
    // }
    setIsValid(valid);
  };
  const changeQuestionnaire = (field: string, val: string | any[]): void => {
    let p = {
      ...questionnaire,
      [field]: val,
    };
    setQuestionnaire(p);
  };
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size={size}>
        <ModalHeader toggle={toggle}> Partner Questionnaire</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="app_names">
                    App Names (separated with comma)
                  </Label>
                  <Input
                    value={questionnaire.app_names}
                    className="input-white"
                    onChange={(e) =>
                      changeQuestionnaire("app_names", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="Name">Appstore\Google Play Link</Label>
                  <Input
                    className="input-white"
                    value={questionnaire.link}
                    onChange={(e) =>
                      changeQuestionnaire("link", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="Name">Number of Unique Users</Label>
                  <Input
                    type="number"
                    className="input-white"
                    value={questionnaire.unique_users}
                    onChange={(e) =>
                      changeQuestionnaire("unique_users", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="app_name">Number of Total Installs</Label>
                  <Input
                    type="number"
                    value={questionnaire.total_installs}
                    className="input-white"
                    onChange={(e) =>
                      changeQuestionnaire("total_installs", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="app_name">
                    On which Platfroms Your Apps Are Running
                  </Label>
                  <Input
                    className="input-white"
                    type="select"
                    value={questionnaire.device_type}
                    onChange={(e) => {
                      onChangeMulti(e);
                    }}
                    multiple
                  >
                    <option value={1}>Mobile Android</option>
                    <option value={2}>Mobile IOS</option>
                    <option value={3}>Desktop</option>
                    <option value={4}>Streamers\Fire TV</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="Name">Billing Contact</Label>
                  <Input
                    className="input-white"
                    value={questionnaire.billing_contact}
                    onChange={(e) =>
                      changeQuestionnaire("billing_contact", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="Name">
                    Method of Communication (Skype, Phone, Email)
                  </Label>

                  <Input
                    className="input-white"
                    type="select"
                    value={questionnaire.communication_method}
                    onChange={(e) =>
                      changeQuestionnaire(
                        "communication_method",
                        e.target.value
                      )
                    }
                  >
                    <option value="Phone">Phone</option>
                    <option value="Skype">Skype</option>
                    <option value="Email">Email</option>
                    <option value="">Other</option>
                  </Input>
                </FormGroup>
              </Col>
              {showCommunicationMethodInput && (
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="Name">Enter the Method of Communication</Label>
                    <Input
                      className="input-white"
                      value={questionnaire.communication_method}
                      onChange={(e) =>
                        changeQuestionnaire(
                          "communication_method",
                          e.target.value
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              {questionnaire.communication_method === "Skype" && (
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="Name">Enter skype username</Label>
                    <Input
                      className="input-white"
                      value={questionnaire.skype_username}
                      onChange={(e) =>
                        changeQuestionnaire("skype_username", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              {questionnaire.communication_method === "Phone" && (
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="Name">Enter phone number</Label>
                    <Input
                      className="input-white"
                      value={questionnaire.phone}
                      onChange={(e) =>
                        changeQuestionnaire("phone", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Loading loading={loading} height={100} />
          {!loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                color="secondary"
                onClick={toggle}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button color="success" onClick={save} disabled={!isValid}>
                Send
              </Button>
            </div>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default QuestionnaireModal;
