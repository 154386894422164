import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";

import AdminLayout from "layouts/Admin/Admin";
import SignIn from "views/SignIn";
import Register from "views/Register";
import Verify from "views/Verify";
import CheckYourEmail from "views/CheckYourEmail";

import "assets/scss/black-dashboard-react.scss";
// import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const hist = createBrowserHistory();
// const store = configureStore();
ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/verify/:id" component={Verify} />
          <Route exact path="/check-your-email" component={CheckYourEmail} />

          <Route exact path="/" component={SignIn} />
          {/* <Route component={NotFound} /> */}
        </>
      </Switch>
      <ToastContainer />
    </Router>
  </Provider>,
  document.getElementById("root")
);
