import $axios from "./../utils/axios";

async function getPublishers() {
  let res = await $axios.get("publishers");
  return res.data;
}

function getPublisherUniques(
  fromDate,
  toDate,
  chosenPublisher,
  showCountries,
  chosenCountry
) {
  return $axios.get("publishers/uniques", {
    params: {
      fromDate: `${fromDate}`,
      toDate: `${toDate}`,
      name: `${chosenPublisher.publisher_name}`,
      showCountries: `${showCountries}`,
      chosenCountry: `${chosenCountry}`,
    },
  });
}

function getTopPublishersActives(fromDate, toDate, device) {
  return $axios.get("publishers/topactives", {
    params: {
      fromDate: `${fromDate}`,
      toDate: `${toDate}`,
      device: `${device}`,
    },
  });
}

function loadActiveIps(publisher, showCountry, country) {
  return $axios.get("publishers/active-ips", {
    params: {
      publisher: publisher,
      showCountry: showCountry,
      country: country,
    },
  });
}

function getTopPublishersCountries(fromDate, toDate, device) {
  return $axios.get("publishers/topcountries", {
    params: {
      fromDate: `${fromDate}`,
      toDate: `${toDate}`,
      device: `${device}`,
    },
  });
}

function getCountries() {
  return $axios.get("publishers/countries");
}

function getMobilePublishers() {
  return $axios.get("publishers/mobiles");
}

function getStreamerPublishers() {
  return $axios.get("publishers/streamers");
}

function getAllPublishers() {
  return $axios.get("publishers/all");
}

function getPublisher(id) {
  return $axios.get(`publishers/${id}`);
}

function getPublisherPaymentData(id) {
  return $axios.get(`publishers/paymentdata/${id}`);
}

function deletePublisher(id) {
  return $axios.delete(`publishers/${id}`);
}

function blockPublisher(id, blocked) {
  return $axios.put(`publishers/block/${id}`, { blocked });
}

function getPublisherMonthlyEarnings(id) {
  return $axios.get(`publishers/monthlyearnings/${id}`);
}

function addPublisher(publisher) {
  return $axios.post("publishers", {
    publisher: {
      publisher_name: `${publisher.publisher_name}`,
      publisher_username: `${publisher.publisher_username}`,
      publisher_password: `${publisher.publisher_password}`,
      publisher_contact_email: `${publisher.publisher_contact_email}`,
      publisher_company_name: `${publisher.publisher_company_name}`,
      is_hours_shown: `${publisher.is_hours_shown}`,
      publisher_price: `${publisher.publisher_price}`,
      publisher_is_streamer: `${+publisher.publisher_is_streamer}`,
    },
  });
}

function loadBillingData(publisher) {
  return $axios.post("loadbillingdata", {
    publisher,
  });
}

function editPublisher(publisher, is_password_changed) {
  return $axios.put(`publishers/${publisher.row_id}`, {
    publisher,
    is_password_changed,
  });
}

function NewEditPublisher(publisher) {
  return $axios.put(`publishers/edit/${publisher.row_id}`, {
    publisher,
  });
}

async function publisherQuestionnair(publisher, data) {
  return await $axios.post(`publishers/${publisher.row_id}/info`, data);
}

async function uploadFile(files, selectedPublisher, fileType, fileId) {
  let formData = new FormData();
  files.map((file) => formData.append("files", file.file));
  formData.append("publisher", selectedPublisher.row_id);
  formData.append("fileType", fileType);
  formData.append("ref_id", fileId);
  return await $axios.post(`publishers/upload`, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data;charset=UTF-8;",
    },
  });
}

async function insertPaymentData(state) {
  return await $axios.put("publishers/paymentdata", state);
}

async function getRequestsRevenues(state) {
  return await $axios.get(`publishers/${state.id}/metrics/daily`, {
    params: {
      fromDate: state.fromDate,
      toDate: state.toDate,
      user: state.user,
      device: state.device,
    },
  });
}

async function loadDiscovery(
  publisher,
  fromDate,
  toDate,
  showCountries,
  showCampaign
) {
  let res = await $axios.get(`publishers/${publisher}/discovery`, {
    params: {
      fromDate,
      toDate,
      showCountries,
      showCampaign,
    },
  });
  return res.data.data;
}

async function loadDiscoveryPublishers(publisher) {
  let res = await $axios.get(`publishers/discovery/all`);
  return res.data.data;
}

export const PublisherService = {
  getPublishers,
  getPublisher,
  addPublisher,
  getAllPublishers,
  editPublisher,
  NewEditPublisher,
  getPublisherUniques,
  getTopPublishersActives,
  loadBillingData,
  loadDiscovery,
  loadDiscoveryPublishers,
  getTopPublishersCountries,
  getCountries,
  publisherQuestionnair,
  getMobilePublishers,
  getStreamerPublishers,
  uploadFile,
  insertPaymentData,
  getPublisherPaymentData,
  getPublisherMonthlyEarnings,
  getRequestsRevenues,
  deletePublisher,
  blockPublisher,
  loadActiveIps,
};
