import axios from "axios";
import { toast } from "react-toastify";

let $axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

$axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

$axios.interceptors.response.use(
  (response) => {
    if (response && response.data.data) {
      if (!response.data.success) {
        let message;
        if (response.data.message) {
          message = response.data.message;
        } else {
          message = "Error";
        }
        toast.error(message);
      }
    }
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error && originalRequest) {
      // token expired
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        localStorage.clear();
        window.location.reload();
        window.location.href = "/signin";
      } else {
        toast.error("Error");
      }
    }
    return Promise.reject(error);
  }
);

export default $axios;
