import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Col, Button, Container, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { UserService } from "services/UserService";
import { LoopCircleLoading } from "react-loadingg";
import $axios from "utils/axios";
import { ShowOnlyInstall } from "utils/config";

const jwt = require("jsonwebtoken");

const SignIn = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("impersonate")) {
      let decoded = jwt.decode(localStorage.getItem("impersonate"));
      if (decoded.exp * 1000 > Date.now()) {
        $axios.get(`publishers/${decoded.id}`).then(async (data) => {
          await dispatch({ type: "SET_USER", user: data.data.data });
          await dispatch({
            type: "SET_SELECTED_PUBLISHER",
            publisher: data.data.data,
          });
          // setUser(data.data.data);
          // if (data.data.block === 0) {
          redirectToDashboard();
          // } else return;
        });
      }
    } else if (localStorage.getItem("token")) {
      let decoded = jwt.decode(localStorage.getItem("token"));
      if (decoded.exp * 1000 > Date.now()) {
        // redirectToDashboard();

        $axios.get(`publishers/${decoded.id}`).then(async (data) => {
          await dispatch({ type: "SET_USER", user: data.data.data });
          await dispatch({
            type: "SET_SELECTED_PUBLISHER",
            publisher: data.data.data,
          });
          // setUser(data.data.data);
          // if (data.data.block === 0) {
          redirectToDashboard();
          // } else return;
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let valid = email !== "" && password !== "";
    setIsValid(valid);
  }, [password, email]);

  const redirectToDashboard = () => {
    let decoded = jwt.decode(localStorage.getItem("token"));
    if (ShowOnlyInstall.includes(decoded.id)) {
      document.location.href = "/admin/installs";
    } else {
      document.location.href = "/admin/dashboard";
    }

    // const isAdmin = decoded.is_admin;
    // if (isAdmin) {
    // document.location.href = "/admin/dashboard";
    // } else {
    // document.location.href = "/admin/dashboard";
    // }
  };

  const login = async (e: { preventDefault: () => void }) => {
    setLoading(true);
    setErrorMessage(null);
    e.preventDefault();

    const res = await UserService.login({
      email,
      password,
    }).catch((err) => {
      console.log(err);
      setErrorMessage(err.response.data.message);
    });
    setLoading(false);
    if (res && res.data && res.data.success) {
      const token = res.data.token;
      localStorage.setItem("token", token);

      let decoded = jwt.decode(token);
      await dispatch({ type: "SET_TOKEN", token });

      $axios.get(`publishers/${decoded.id}`).then(async (data) => {
        await dispatch({ type: "SET_USER", user: data.data.data });
        await dispatch({
          type: "SET_SELECTED_PUBLISHER",
          publisher: data.data.data,
        });

        await setUser(data.data.data);

        if (data.data.data && data.data.data.block === 0) {
          redirectToDashboard();
        } else {
          return;
        }
      });
    }
  };

  return (
    <>
      <Container
        style={{
          position: "relative",
          display: "flex",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "550px",
          height: "100vh",
        }}
      >
        <Col
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            textAlign: "center",
            background: `linear-gradient(60deg, #6760f3 0, #8557ea 16.67%, #9c4de0 33.33%, #af42d4 50%, #bf35c7 66.67%, #cc26ba 83.33%, #d710ab 100%)`,
          }}
        >
          <Form>
            <img src={logo} alt="Logo" style={{ width: "90px" }} />
            <p className="h3 text-center" style={{ color: "white" }}>
              Login
            </p>
            <div style={{ color: "white" }}>
              <Col>
                <FormGroup>
                  <Input
                    placeholder=" Type your email"
                    style={{ backgroundColor: "white", color: "black" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Type your password"
                    style={{ backgroundColor: "white", color: "black" }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </div>
            <div className="text-center">
              {errorMessage ? (
                <div style={{ color: "#fff" }}>{errorMessage}</div>
              ) : null}
              {user.block === 1 ? (
                <div style={{ color: "white" }}>
                  You are currently blocked from the platform
                </div>
              ) : !loading ? (
                <Button
                  onClick={login}
                  disabled={!isValid}
                  type="submit"
                  style={{ marginTop: "50px" }}
                >
                  Login
                </Button>
              ) : (
                <LoopCircleLoading />
              )}
            </div>
          </Form>

          <div className="mt-3">
            <Link to="register" style={{ color: "#fff" }}>
              Register <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </Col>
      </Container>
    </>
  );
};
export default SignIn;
