import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import $axios from "utils/axios";

const UpdateInfo = (props) => {
  const { user } = props;

  const [questionnaire, setQuestionnaire] = useState({
    app_names: "",
    billing_contact: "",
    communication_method: "",
    device_type: "",
    total_installs: "",
    unique_users: "",
    link: "",
  });

  useEffect(() => {
    (async () => {
      let res = await $axios.get(`publishers/${user.row_id}/info`);
      if (res.data.data) {
        setQuestionnaire(res.data.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="content">
        <Col>
          <Row>
            <Card>
              <CardHeader>
                <h5 className="title">Info</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex c-w">
                      <div>Communication Method:</div>
                      <div style={{ fontWeight: 600 }} className="ml-2">
                        {questionnaire.communication_method}
                      </div>
                    </div>

                    <div className="d-flex c-w mt-3">
                      <div>Device Type:</div>
                      <div style={{ fontWeight: 600 }} className="ml-2">
                        {user.publisher_is_streamer ? "Streamer" : "Mobile"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default UpdateInfo;
