import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";

const ChangeDevice = (props: {
  selected: string;
  handleChangeDevice: Function;
  showAll?: boolean;
  text?: string;
}) => {
  const {
    selected,
    handleChangeDevice,
    showAll = false,
    text = "Device",
  } = props;

  const [device, setDevice] = useState(selected);

  useEffect(() => {
    setDevice(device);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleChange = (e: { target: { value: any } }) => {
    const newDevice = e.target.value;
    setDevice(newDevice);
    handleChangeDevice(newDevice);
  };

  return (
    <div className="d-flex align-items-center">
      <span
        style={{
          color: "white",
          marginRight: "0",
          marginLeft: "15px",
        }}
      >
        {text}
      </span>
      <Input
        className="input-white"
        type="select"
        onChange={handleChange}
        style={{
          width: "200px",
          marginLeft: "10px",
        }}
        value={device}
      >
        {showAll && <option value="all"> All </option>}
        <option value="mobile">Mobile </option>
        <option value="streamer">Streamer </option>
      </Input>
    </div>
  );
};
export default ChangeDevice;
