import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { validateEmail } from "../../utils/heplers";

const AddPublisher = (props: {
  IsEdit: boolean;
  onFinish: Function;
  show: boolean;
  size: any;
  publisherProp: Publisher;
  onShowChange: Function;
}) => {
  const { IsEdit, onFinish, show, size, publisherProp, onShowChange } = props;
  const [modal, setModal] = useState(show);
  const [isValid, setIsValid] = useState(false);

  const [publisher, setPublisher] = useState(publisherProp);

  const toggle = () => {
    onShowChange(!modal);
    setModal(!modal);
  };

  useEffect(() => {
    setPublisher(publisherProp);
  }, [publisherProp]);

  useEffect(() => {
    let invalides = Object.keys(publisher).filter((field: string) => {
      return publisher[field] != null && publisher[field].length === "";
    });
    if (
      !validateEmail(publisher.publisher_contact_email) ||
      invalides.length > 0
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [publisher]);

  const changePublisher = (field: string, val: string | boolean | number) => {
    let p = {
      ...publisher,
      [field]: val,
    };
    setPublisher(p);
  };

  const save = () => {
    onFinish(publisher);
  };

  useEffect(() => {
    setModal(show);
  }, [show]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size={size}>
        <ModalHeader toggle={toggle}> Add publisher</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="Name">Name</Label>
                  <Input
                    style={{ backgroundColor: "white", color: "black" }}
                    value={publisher.publisher_name}
                    onChange={(e) =>
                      changePublisher("publisher_name", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="publisher_username">Username</Label>
                  <Input
                    value={publisher.publisher_username}
                    style={{ backgroundColor: "white", color: "black" }}
                    onChange={(e) =>
                      changePublisher("publisher_username", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormGroup>
                    <Label for="Nampublisher_password">Password</Label>
                    <Input
                      style={{ backgroundColor: "white", color: "black" }}
                      value={publisher.publisher_password}
                      onChange={(e) =>
                        changePublisher("publisher_password", e.target.value)
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Label style={{ visibility: "hidden" }}> d</Label>
                    <Button
                      onClick={() => {
                        changePublisher(
                          "publisher_password",
                          Math.random().toString(36).slice(-8)
                        );
                      }}
                      style={{ padding: "10px" }}
                    >
                      Generate
                    </Button>
                  </FormGroup>
                </div>
              </Col>

              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="publisher_contact_email"> Contact Email</Label>
                  <Input
                    value={publisher.publisher_contact_email}
                    style={{ backgroundColor: "white", color: "black" }}
                    onChange={(e) =>
                      changePublisher("publisher_contact_email", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="publisher_company_name">Company</Label>
                  <Input
                    style={{ backgroundColor: "white", color: "black" }}
                    value={publisher.publisher_company_name}
                    onChange={(e) =>
                      changePublisher("publisher_company_name", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="is_hours_shown">
                    Is The "Hours Column" Shown
                  </Label>
                  <Input
                    type="select"
                    onChange={(e) =>
                      changePublisher("is_hours_shown", e.target.value)
                    }
                    style={{ color: "black" }}
                    value={publisher.is_hours_shown}
                  >
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <section style={{ border: "2px solid #4e504e11", padding: "10px" }}>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="publisher_price">Publisher Price</Label>
                    <Input
                      type="number"
                      style={{ backgroundColor: "white", color: "black" }}
                      value={publisher.publisher_price}
                      onChange={(e) =>
                        changePublisher("publisher_price", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="publisher_is_streamer">Publisher Type</Label>
                    <Input
                      type="select"
                      style={{ backgroundColor: "white", color: "black" }}
                      value={publisher.publisher_is_streamer}
                      onChange={(e) =>
                        changePublisher("publisher_is_streamer", e.target.value)
                      }
                    >
                      <option value={0}>Mobile</option>
                      <option value={1}>Streamer</option>
                      <option value={2}>Streamer gms</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </section>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              color="secondary"
              onClick={toggle}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button color="success" onClick={save} disabled={!isValid}>
              {IsEdit ? "Edit" : "Add"} publisher
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddPublisher;
