import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormFeedback,
} from "reactstrap";
import $axios from "utils/axios";
import { toast } from "react-toastify";

const UpdatePassword = (props) => {
  const { user } = props;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, confirmPassword]);

  const save = async () => {
    let res = await $axios.put(`publishers/edit-password/${user.row_id}`, {
      password: newPassword,
      confirmPassword: confirmPassword,
    });

    if (res.data.success) {
      toast.success("The password has been updated");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <>
      <div className="content">
        <Col>
          <Row>
            <Card>
              <CardHeader>
                <h5 className="title">Edit Password</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="publisher_password">New Password</Label>
                        <Row>
                          <Col xs={9}>
                            <Input
                              type="text"
                              autoComplete="off"
                              data-lpignore="true"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </Col>
                          <Col xs={3}>
                            <Button
                              onClick={() => {
                                setNewPassword(
                                  Math.random().toString(36).slice(-8)
                                );
                              }}
                              style={{ padding: "10px" }}
                            >
                              Generate
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="publisher_company_name">
                          Confirm Password
                        </Label>
                        <Input
                          type="text"
                          autoComplete="off"
                          data-lpignore="true"
                          invalid={isValid}
                          value={confirmPassword}
                          style={{ backgroundColor: "white", color: "black" }}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <FormFeedback tooltip>
                          Passwords don't match
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  onClick={save}
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  disabled={isValid || newPassword === ""}
                >
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default UpdatePassword;
