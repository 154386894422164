import React from "react";

const FilterWrapper = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #b3b3b3",
        padding: "10px",
        backgroundColor: "#27293d",
      }}
    >
      <div
        style={{
          color: "#fff",
          borderBottom: "1px solid #585757",
          paddingBottom: "6px",
        }}
      >
        Filters <i className="fas fa-filter"></i>
      </div>
      <div
        className="filtersWrapper"
        style={{
          flexWrap: "wrap",
          display: "flex",
          marginTop: "10px",
          padding: " 10px 0 10px 0",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default FilterWrapper;
