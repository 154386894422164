import { PublisherService } from "../services/PublisherService";
import { toast } from "react-toastify";

export const getPublishers = () => {
  return async (dispatch) => {
    const res = await PublisherService.getPublishers();
    const publishers = res.data;
    dispatch({ type: "GET_PUBLISHERS", publishers });
  };
};

export const getPublisherUniques = (state) => {
  return async (dispatch) => {
    const res = await PublisherService.getPublisherUniques(state);
    const uniques = res.data.data;

    dispatch({ type: "GET_PUBLISHER_UNIQUES", uniques });
  };
};

export const getCountryUniques = (state) => {
  return async (dispatch) => {
    const res = await PublisherService.getCountryUniques(state);
    const countryUniques = res.data.data;
    dispatch({ type: "GET_COUNTRY_UNIQUES", countryUniques });
  };
};

export const getAllPublishers = () => {
  return async (dispatch) => {
    const res = await PublisherService.getAllPublishers();
    const allpublishers = res.data.data;

    dispatch({ type: "GET_ALLPUBLISHERS", allpublishers });
  };
};

export const getPublisher = (state) => {
  return async (dispatch) => {
    const res = await PublisherService.getPublisher(state);
    const publisher = res.data;
    localStorage.setItem("user", JSON.stringify(publisher));
    dispatch({ type: "GET_PUBLISHER", publisher });
  };
};

export const getEditPublisher = (id) => {
  return async (dispatch) => {
    const res = await PublisherService.getPublisher(id);
    const editPublisher = res.data.data[0];
    dispatch({ type: "GET_EDIT_PUBLISHER", editPublisher });
  };
};

export const editPublisher = (state, is_password_changed) => {
  return async (dispatch) => {
    let res = await PublisherService.editPublisher(state, is_password_changed);
    if (res.data.success) {
      toast.success("The publisher has been updated");
    }
    await dispatch({
      type: "EDIT_PUBLISHER_FROM_PUBLISHERS",
      publisher: state,
    });
  };
};

export const NewEditPublisher = (publisher, updateUser = true) => {
  return async (dispatch) => {
    let res = await PublisherService.NewEditPublisher(publisher);
    if (res.data.success) {
      toast.success("The publisher has been updated");
    }
    if (updateUser) {
      await dispatch({
        type: "UPDATE_SELECTED_PUBLISHER",
        publisher: res.data.data,
      });
    } else {
      await dispatch({
        type: "EDIT_PUBLISHER_FROM_PUBLISHERS",
        publisher: res.data.data,
      });
    }
  };
};

export const deletePublisher = (id) => {
  return async (dispatch) => {
    let res = await PublisherService.deletePublisher(id);
    if (res.data.success) {
      toast.success("The publisher has been deleted");
    }
    await dispatch({
      type: "REMOVE_PUBLISHER_FROM_PUBLISHERS",
      id,
    });
  };
};

export const addPublisher = (publisher) => {
  return async (dispatch) => {
    const res = await PublisherService.addPublisher(publisher);
    if (res.data.success) {
      toast.success("The publisher has been added");
    }
    const addedPublisher = res.data;
    dispatch({ type: "ADD_PUBLISHER", addedPublisher });
  };
};

export const loadBillingData = (publisher) => {
  return async (dispatch) => {
    const res = await PublisherService.loadBillingData(publisher);
    const billingData = res.data;
    dispatch({ type: "BILLING_DATA", billingData });
  };
};

export const getCountries = () => {
  return async (dispatch) => {
    const res = await PublisherService.getCountries();
    const countries = res.data.data;
    dispatch({ type: "GET_COUNTRIES", countries });
  };
};

export const getMobilePublishers = () => {
  return async (dispatch) => {
    const res = await PublisherService.getMobilePublishers();
    const mobiles = res.data.data;
    dispatch({ type: "GET_MOBILE_PUBLISHERS", mobiles });
  };
};

export const getStreamerPublishers = () => {
  return async (dispatch) => {
    const res = await PublisherService.getStreamerPublishers();
    const streamers = res.data.data;
    dispatch({ type: "GET_STREMER_PUBLISHERS", streamers });
  };
};

export const getRequestsRevenues = (state) => {
  return async (dispatch) => {
    const res = await PublisherService.getRequestsRevenues(state);
    const mobileRevenues = res.data.data;
    dispatch({ type: "GET_REQUESTS_REVENUES", mobileRevenues });
  };
};
