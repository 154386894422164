import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalNinja = (props) => {
  const {
    buttonColor = "primary",
    buttonLabel = "Open",
    buttonSize = "lg",
    title = "",
    BtnLeft = false,
    BtnRight = false,
    onBtnRight = false,
    styleBody = {},
    isHtml = false,
    open = false,
    mainButton = true,
  } = props;

  const [modal, setModal] = useState(open);

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    setModal(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {mainButton ? (
        <Button color={buttonColor} onClick={toggle} size={buttonSize}>
          {buttonLabel}
        </Button>
      ) : null}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody style={styleBody}>
          {isHtml ? (
            <div dangerouslySetInnerHTML={{ __html: props.children }}></div>
          ) : (
            <div>{props.children}</div>
          )}
        </ModalBody>
        <ModalFooter>
          {BtnLeft && (
            <Button color="primary" onClick={toggle}>
              {BtnLeft}
            </Button>
          )}
          {BtnRight && (
            <Button color="primary" onClick={onBtnRight}>
              {BtnRight}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalNinja;
