import React, { useEffect, useState } from "react";
import SelectPublisher from "components/Global/SelectPublisher";
import DataTable, { createTheme } from "react-data-table-component";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "../components/Filters/Switch";
import Loading from "../components/Global/Loading";
import { themeTable, paginationPerPage, startOfMonth } from "../utils/heplers";
import FilterWrapper from "../components/Filters/FilterWrapper";
import $axios from "utils/axios";
import PublisherName from "components/Global/PublisherName";
import CsvGenerator, { DataTableFormatData } from "components/csvGenerator";

createTheme("solarized", themeTable);

const PublishersPayments = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [earnings, setEarnings] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showDate, setShowDate] = useState(false);
  const [publisher, setPublisher] = useState<number>();
  const [paid, setPaid] = useState<string>();
  const [isInvoice, setIsInvoice] = useState();
  const [isHideLess50, setisHideLess50] = useState(true);

  const [newData, setNewData] = useState([]);
  const [c, setC] = useState({});
  let [proxy, setProxy] = useState([]);
  let [colsDict, setColsDict] = useState([]);
  useEffect(() => {
    (async function () {
      setLoading(true);
      const res = await $axios.get(`earnings/payments`, {
        params: {
          publisher,
          startDate: startOfMonth(startDate),
          paid,
          showDate,
        },
      });
      let { data } = res.data;

      const filterOutEmpty = data.filter((i: any) => i.publisher !== null);

      const prepareData = filterOutEmpty.map((i: any) => {
        const { publisher, accumulate_month } = i;
        return {
          ...i,
          publisher: publisher?.publisher_name,
          accumulate_month: accumulate_month ? accumulate_month : "",
        };
      });

      setEarnings(prepareData);
      setNewData(prepareData);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, showDate, publisher, paid]);

  const updatePaid = async (id: number, index: number, p: boolean) => {
    const paid = p ? 1 : 0;
    // let newEarnings = [...earnings];
    // newEarnings[index].paid = paid;
    // setEarnings([...newEarnings]);
    let res = await $axios.put("publishers/paidpayment", {
      id,
      paid,
      publisher,
      showDate,
    });

    console.log("earnings", earnings);

    let ids = res.data.data.map((d: string) => parseInt(d));
    let updatedData = earnings.map((d) => {
      if (ids.includes(d.row_id)) {
        d.paid = paid;
      }
      return d;
    });
    setEarnings([...updatedData]);
  };

  useEffect(() => {
    if (isInvoice === "2") {
      setEarnings(newData);
    }

    if (isInvoice === "1") {
      const withInvoices = newData.filter((n: any) => n.file !== null);
      setEarnings(withInvoices);
    }
    if (isInvoice === "0") {
      const withoutInvoices = newData.filter((n: any) => n.file == null);
      setEarnings(withoutInvoices);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
    if (isHideLess50) {
      const data = newData.filter((n: any) => n.invoice_total > 50);
      setEarnings(data);
    }

    if (!isHideLess50) {
      setEarnings(newData);
    }
  }, [isInvoice, isHideLess50, newData]);

  const sortGlobal = (a: any, b: any, param: string) => {
    a = parseFloat(a[param].replace(",", "").replace("$", ""));
    b = parseFloat(b[param].replace(",", "").replace("$", ""));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortAmount = (a: any, b: any) => {
    return sortGlobal(a, b, "revenues");
  };

  const sortInvoices = (a: any, b: any) => {
    return sortGlobal(a, b, "invoice_total");
  };

  let columns = [
    {
      name: "Date",
      selector: "month_year",
      sortable: true,
      t: "date",

      // format: (row: any) => moment(row.month_year).format("MM-YYYY"),
    },
    {
      name: "publisherName",
      selector: "publisher",
      sortable: true,
      t: "publisherName",

      cell: (row: any) => <PublisherName name={row.publisher} />,
    },
    {
      name: "Amount",
      selector: "revenues",
      t: "money",
      sortable: true,
      sortFunction: sortAmount,
    },
    {
      name: "Invoice",
      selector: "invoice_total",
      t: "money",
      sortable: true,
      sortFunction: sortInvoices,
      // format: (row: any) => "$" + formatNumber(row.invoice_total),
    },
    {
      name: "Accumulate Months",
      selector: "accumulate_month",
      t: "none",

      sortable: true,
      format: (row: any) => (row.accumulate_month ? Object.values(JSON.parse(row.accumulate_month)).join(", ") : ""),
    },
    {
      name: "Download Invoice",
      selector: "file.publisher_id",
      t: "none",

      sortable: true,
      minWidth: "320",
      cell: (row: any) => (
        <div
          style={{
            padding: "0 10px",
          }}
        >
          {row.file ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_API_URL}files?filename=${row.file.filename}&token=${localStorage.getItem("token")}`}
                style={{ marginRight: 10 }}
              >
                {row.file.original_name}
              </a>
            </div>
          ) : (
            <div>File not uploaded</div>
          )}
        </div>
      ),
    },
    {
      name: "Paid",
      t: "none",

      cell: (row: any, index: number) => (
        <div>
          <Switch onChange={(e: boolean) => updatePaid(row.row_id, index, e)} isShow={row.paid === 1} text={row.invoice_total < 50 ? "Less than $50" : ""} disabled={row.invoice_total < 50} />
        </div>
      ),
    },
  ];
  useEffect(() => {
    const m = columns.map((i) => {
      return { colName: i.selector, displayName: i.name };
    });
    const c = columns.reduce((prev: any, cur: any, idx: number) => {
      if (!cur.selector) return prev;
      const selector: string = (cur.selector as String).indexOf(".") > 0 ? cur.selector.split(".")[1] : cur.selector;
      prev[selector] = { name: selector, type: cur.t };
      // console.log("🚀 ~ file: PublishersPayments.tsx ~ line 176 ~ c ~ selector", selector,prev)
      return prev;
    }, {});
    setC({ rows: earnings, columns: c as any });
    setColsDict(m);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earnings]);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Publishers Payments</div>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <FilterWrapper>
                  {showDate && (
                    <DatePicker
                      selected={startDate}
                      onChange={(date: Date) => {
                        setStartDate(date);
                      }}
                      dateFormat="MM.yyyy"
                      showMonthYearPicker
                    />
                  )}
                  <Switch isShow={showDate} onChange={() => setShowDate(!showDate)} text="Date" />
                  <Switch isShow={isHideLess50} onChange={() => setisHideLess50(!isHideLess50)} text="Hide less than $50" />
                  <div>
                    <Input
                      className="input-white"
                      type="select"
                      onChange={(e) => setPaid(e.target.value)}
                      style={{
                        width: "200px",
                        margin: "0 10px",
                      }}
                    >
                      <option value=" || isInvoice == 1">Paid && Unpaid </option>
                      <option value="1">Paid </option>
                      <option value="0">Unpaid </option>
                    </Input>
                  </div>
                  <div>
                    <Input
                      className="input-white"
                      type="select"
                      onChange={(e: any) => setIsInvoice(e.target.value)}
                      style={{
                        width: "200px",
                        margin: "0 10px",
                      }}
                    >
                      <option value="2">Invoices - All</option>
                      <option value="1">With Invoice </option>
                      <option value="0">No Invoice </option>
                    </Input>
                  </div>
                  <SelectPublisher selected="All" changed={(r: { value: number; label: string }) => setPublisher(r.value)} />
                </FilterWrapper>
                <Loading loading={loading} />

                {earnings.length ? (
                  <>
                    <CsvGenerator whiteList={colsDict} data={c as unknown as DataTableFormatData} setProxy={setProxy} />
                    <DataTable
                      fixedHeader
                      highlightOnHover
                      columns={columns}
                      data={proxy}
                      pagination={true}
                      paginationPerPage={paginationPerPage}
                      theme="solarized"
                      overflowY={true}
                      overflowYOffset="100%"
                    />
                  </>
                ) : (
                  <div className="no-data">No data available</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PublishersPayments;
