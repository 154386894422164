import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { PublisherService } from "./../services/PublisherService";
import DataTable, { createTheme } from "react-data-table-component";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import Loading from "./../components/Global/Loading";
import SelectPublisher from "./../components/Discovery/SelectPublisher";
import InfoBox from "./../components/Discovery/InfoBox";
import {
  themeTable,
  lastSevenDays,
  today,
  formatNumber,
  paginationPerPage,
} from "../utils/heplers";
import Switch from "../components/Filters/Switch";
import Text from "../components/Filters/Text";
import FilterWrapper from "./../components/Filters/FilterWrapper";
import DateRange from "./../components/Filters/DateRange";
import ShowCountry from "components/Global/ShowCountry";

createTheme("solarized", themeTable);
interface DiscoveryPublisher {
  install_date: string;
  publisher: string;
  installs: number;
  uninstalled: number;
}
const DailyUsers: React.FC = () => {
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(lastSevenDays());
  const [toDate, setToDate] = useState(today());
  const [loading, setLoading] = useState(false);
  const [showCountries, setShowCountries] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  const [total, setTotal] = useState({ install: 0, uninstall: 0 });

  const [SearchByCampaign, setSearchByCampaign] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const selectedDiscoveryPublisher = useSelector(
    (state) => state.publisherReducer.selectedDiscoveryPublisher
  );

  useEffect(() => {
    (async function () {
      if (selectedDiscoveryPublisher) {
        setLoading(true);
        let data = await PublisherService.loadDiscovery(
          selectedDiscoveryPublisher.label,
          fromDate,
          toDate,
          showCountries,
          showCampaign
        );
        setData(data);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiscoveryPublisher, toDate, showCountries, showCampaign]);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, SearchByCampaign]);

  const filterData = () => {
    const newFilteredData = data.filter((e: any) => {
      if (!showCampaign) {
        return true;
      }
      return e.campaignId
        .toLowerCase()
        .includes(SearchByCampaign.toLowerCase() || SearchByCampaign);
    });
    calculateTotal(newFilteredData);
    setFilteredData(newFilteredData);
  };

  const calculateTotal = (arr: DiscoveryPublisher[]) => {
    let totalInstall = 0;
    let totalUninstall = 0;
    arr.forEach((r: DiscoveryPublisher) => {
      totalInstall += r.installs;
      totalUninstall += r.uninstalled;
    });
    setTotal({ install: totalInstall, uninstall: totalUninstall });
  };

  const handleDate = async (e: { start: string; end: string }) => {
    await setFromDate(e.start);
    await setToDate(e.end);
  };

  const percentage = (partialValue: number, totalValue: number) => {
    return (100 * partialValue) / totalValue;
  };

  let columns = [
    {
      name: "Date",
      sortable: true,
      cell: (row: DiscoveryPublisher) => (
        <div data-tag="allowRowEvents">
          <Moment format="D MMM YYYY">{row.install_date}</Moment>
        </div>
      ),
    },
    {
      name: "Publisher Name",
      selector: "publisher",
      sortable: true,
    },
    {
      name: `Installed (${formatNumber(total.install)})`,
      selector: "installs",
      sortable: true,
    },
    {
      name: `Unistalled (${formatNumber(total.uninstall)})`,
      selector: "uninstalled",
      sortable: true,
      cell: (row: DiscoveryPublisher) => (
        <div data-tag="allowRowEvents">
          {row.uninstalled +
            (row.uninstalled > 0
              ? " (" +
                percentage(row.uninstalled, row.installs).toFixed(0) +
                "%)"
              : "")}
        </div>
      ),
    },
  ];

  if (showCountries) {
    columns.push({
      name: "Country",
      selector: "country",
      sortable: true,
      cell: (row: any) => <ShowCountry country_code={row.country} />,
    });
  }

  if (showCampaign) {
    columns.push({
      name: "Campaign",
      selector: "campaignId",
      sortable: true,
    });
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Libra vpn installs</div>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <FilterWrapper>
                  <DateRange onChange={handleDate} />
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <SelectPublisher />
                  </div>

                  {showCampaign && (
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <Text
                        value={SearchByCampaign}
                        placeholder="Search by campaign"
                        onChange={(val: string) => setSearchByCampaign(val)}
                      />
                    </div>
                  )}

                  <Switch
                    isShow={showCountries}
                    onChange={() => setShowCountries(!showCountries)}
                    text="Show Countries"
                  />

                  <Switch
                    isShow={showCampaign}
                    onChange={() => setShowCampaign(!showCampaign)}
                    text="Show Campaign"
                  />
                </FilterWrapper>
                <Loading loading={loading} />
                {data.length > 0 && !loading ? (
                  <div>
                    <div style={{ marginTop: "10px" }}>
                      <InfoBox
                        data={{
                          uninstall: total.uninstall,
                          install: total.install,
                        }}
                      />
                    </div>
                    <DataTable
                      fixedHeader
                      highlightOnHover
                      columns={columns}
                      data={filteredData}
                      pagination={true}
                      paginationPerPage={paginationPerPage}
                      theme="solarized"
                      overflowY={true}
                      overflowYOffset="100%"
                    />
                  </div>
                ) : (
                  <div className="no-data">No data available</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyUsers;
