import React from "react";
import { Col, Container } from "reactstrap";
import logo from "../assets/img/logo.png";

const CheckYourEmail = () => {
  return (
    <>
      <Container
        style={{
          position: "relative",
          display: "flex",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "550px",
          height: "100vh",
        }}
      >
        <Col
          style={{
            paddingTop: "30px",
            height: 300,
            paddingBottom: "30px",
            textAlign: "center",
            background: `linear-gradient(60deg, #6760f3 0, #8557ea 16.67%, #9c4de0 33.33%, #af42d4 50%, #bf35c7 66.67%, #cc26ba 83.33%, #d710ab 100%)`,
          }}
        >
          <img src={logo} alt="Logo" style={{ width: "90px" }} />
          <p
            className="h3 text-center"
            style={{ color: "white", marginTop: 50 }}
          >
            Check your email inbox
          </p>
        </Col>
      </Container>
    </>
  );
};
export default CheckYourEmail;
