import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import Picker from "./Picker";
const moment = require("moment");

interface Ichart {
  date: string;
  amount: number;
}

const ChartMyEarning = (props: any): JSX.Element => {
  const { data, numberMonth, onUpdate } = props;
  const [earnings, setEarnings] = useState<Ichart[]>([]);
  const [numMonth, setNumMonth] = useState<number>(numberMonth);

  const selectedPublisher = useSelector((state) => state.publisherReducer.selectedPublisher);

  useEffect(() => {
    onUpdate(numMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numMonth]);

  useEffect(() => {
    (async function () {
      if (data) {
        let dataChart: any = Object.keys(data).map((el) => {
          return {
            date: moment(el).format("YY-MM"),
            amount: data[el].revenues,
          };
        });
        setEarnings(dataChart);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher.row_id, data]);

  return (
    <>
      <Picker
        label="My Earnings"
        defaultSelected={numMonth}
        onSelected={(d) => {
          setNumMonth(d);
        }}
      />
      <BarChart width={730} height={250} data={earnings}>
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip formatter={(v: number) => `$ ${v.toLocaleString()}`} />
        <Bar dataKey="amount" fill="#8884d8" />
      </BarChart>
    </>
  );
};

export default ChartMyEarning;