import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const SelectPublisher = (props) => {
  const { changed, selected, showGmsWithStreamer = false, filters = "all" } = props;
  const publishers = useSelector((state) => state.publisherReducer.publishers);
  const [publishersArr, setPublishersArr] = useState(publishers);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selected && publishers.length > 0) {
      let s = publishers.filter((p) => p.publisher_name === selected);
      if (s.length === 1) {
        setSelectedOption({
          value: s[0].row_id,
          label: s[0].publisher_name,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (selectedOption) {
      changed(selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    let pArr = [];

    if (publishers.length > 0) {
      publishers.forEach((r) => {
        if (filters === "all") {
          pArr.push({
            value: r.row_id,
            label: r.publisher_name,
          });
        } else if (filters === "mobile" && r.publisher_is_streamer === 0) {
          pArr.push({
            value: r.row_id,
            label: r.publisher_name,
          });
        } else if ((filters === "streamer" && r.publisher_is_streamer === 1 && r.is_gms === false) || (showGmsWithStreamer && r.publisher_is_streamer === 1)) {
          pArr.push({
            value: r.row_id,
            label: r.publisher_name,
          });
        } else if (filters === "gms" && r.publisher_is_streamer === 1 && r.is_gms === true) {
          pArr.push({
            value: r.row_id,
            label: r.publisher_name,
          });
        }
      });
    }
    setPublishersArr(pArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const changePublisher = (elem) => {
    setSelectedOption(elem);
  };

  return publishers && publishersArr && publishers.length > 0 ? (
    <>
      <div style={{ zIndex: 999, minWidth: "150px" }}>
        <Select options={publishersArr} value={selectedOption} onChange={changePublisher} />
      </div>
    </>
  ) : (
    <div></div>
  );
};

export default SelectPublisher;
