const initialState = {
    usage: {},
    activity: {}
}

export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_USAGE':
            return { ...state, usage: action.usage }
        case 'GET_ACTIVITY':
            return { ...state, activity: action.activity }
        case 'GET_DATA':
            return { ...state, GBdata: action.GBdata }
        default:
            return state;
    }

}
