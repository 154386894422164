import React from "react";
import { Col } from "reactstrap";
const ShowCountry = (props: {
  bg: string;
  title: string;
  value: any;
  icon: string;
}) => {
  const { bg, title, value, icon } = props;
  return (
    <Col md="6" xl="3" className="mt-2">
      <div className={`dash-card dash-card-bg-${bg}`}>
        <i className={`fa fa-${icon}`}></i>

        <div className="content text-left d-flex justify-content-between align-items-center">
          <p className="dash-card-title">{title}</p>
          <span>{value}</span>
        </div>
      </div>
    </Col>
  );
};

export default ShowCountry;
