import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import { Input } from "reactstrap";
import {
  lastSevenDaysMoment,
  todayMoment,
  formatDate,
} from "./../../utils/heplers";
const DateRange = (props) => {
  const { onChange } = props;
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState([
    {
      startDate: lastSevenDaysMoment().toDate(),
      endDate: todayMoment().toDate(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    onChange({
      start: formatDate(dates[0].startDate),
      end: formatDate(dates[0].endDate),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  let body = document.getElementsByTagName("body")[0];
  body.addEventListener("click", (e) => {
    let toClose = false;
    e.target.classList.forEach((c) => {
      if (
        [
          "no-data",
          "card-header",
          "card-body",
          "rdt_TableHeader",
          "rdt_TableCell",
          "content",
          "navbar",
          "row",
          "filtersWrapper",
        ].includes(c)
      ) {
        toClose = true;
      }
    });

    if (toClose) {
      setOpen(false);
    }
  });
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Input
        style={{ backgroundColor: "white", color: "black", width: "190px" }}
        value={
          formatDate(dates[0].startDate) +
          "  ->  " +
          formatDate(dates[0].endDate)
        }
        readOnly
        onClick={() => setOpen(!open)}
      />
      <div
        style={{ position: "absolute", zIndex: 99999, top: "140px" }}
        id="date-rabge-picker"
      >
        {open && (
          <div>
            <div
              onClick={() => setOpen(false)}
              style={{
                textAlign: "end",
                width: "100%",
                color: "#fff",
                fontSize: "19px",
                cursor: "pointer",
              }}
            >
              <i className="fas fa-times-circle"></i>
            </div>

            <DateRangePicker
              onChange={(item) => setDates([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dates}
              direction="horizontal"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRange;
