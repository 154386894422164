import React from "react";
import { useSelector } from "react-redux";
import UpdateProfile from "components/PublisherProfile/UpdateProfile";
import { Row, Col } from "reactstrap";
import UpdatePassword from "components/PublisherProfile/UpdatePassword";
import UpdateInfo from "components/PublisherProfile/UpdateInfo";

const UserProfile = () => {
  const selectedPublisher = useSelector(
    (state) => state.publisherReducer.selectedPublisher
  );

  return (
    <>
      <div className="content">
        <Row>
          <Col xs={12}>
            <UpdateProfile user={selectedPublisher} />
          </Col>
          <Col xs={12}>
            <UpdatePassword user={selectedPublisher} />
          </Col>
          <Col xs={12}>
            <UpdateInfo user={selectedPublisher} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserProfile;
