import $axios from "./../utils/axios";

function login(creds) {
  return $axios.post("auth/login", creds);
}

function getEmailData() {
  return $axios.get("users/getpublishers", );
}

const getUsers = async (state) => {
  let res = await $axios.post("users", {
      fromDate: state.fromDate,
      toDate: state.toDate,
      user: state.publisher_name,
      device: state.device
    
  });
  return res.data;
};

function getInstalls(state) {
  return $axios.get("users/installs", {
    params: {
      fromDate: state.fromDate,
      toDate: state.toDate,
      user: state.publisher_name,
    },
  });
}

function getAllInstalls(state) {
  return $axios.post("users/allinstalls",{
      fromDate: state.fromDate,
      toDate: state.toDate,
      chosenCountry: state.chosenCountry,
      device: state.device
    })
}

function getCountries(state) {
  return $axios.get("users/countries", {
    params: {
      fromDate: state.fromDate,
      toDate: state.toDate,
      user: state.publisher_name,
    },
  });
}

export const UserService = {
  login,
  getUsers,
  getInstalls,
  getAllInstalls,
  getCountries,
  getEmailData
};
