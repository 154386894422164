import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";

import Switch from "../Filters/Switch";
import DateRange from "../Filters/DateRange";
import { lastSevenDays, today } from "../../utils/heplers";
import SelectPublisher from "components/Global/SelectPublisher";
import ChangeCountry from "components/Filters/ChangeCountry";

const DailyUsersFilters = (props: { onFilterChange: Function; filtersProps: { device: string; showPublisher: any }; isMobile: boolean }) => {
  const { onFilterChange, filtersProps, isMobile } = props;
  const user = useSelector((state) => state.userReducer.user);
  const [filters, setFilters] = useState({
    showDate: false,
    showPublisher: filtersProps.showPublisher,
    publisher: user.publisher_is_admin ? null : user.publisher_name,
    showCountry: false,
    country: "All",
    showPrice: false,
    fromDate: lastSevenDays(),
    toDate: today(),
    device: filtersProps.device,
  });

  useEffect(() => {
    changeFilters("device", filtersProps.device);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersProps.device]);
  // useEffect(() => {
  //   // onFilterChange(filters);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters]);
  const changeFilters = (field: string, val: string | boolean | number) => {
    let f = {
      ...filters,
      [field]: val,
    };
    setFilters(f);
  };

  const handleDate = async (e: { start: string; end: string }) => {
    let f = {
      ...filters,
      fromDate: e.start,
      toDate: e.end,
    };
    setFilters(f);
  };



  return (
    <div className="d-flex align-items-center">
      {/* {user.publisher_is_admin === 1 && (
        <div className="d-flex align-items-center mt-3 mr-3">
          <ChangeDevice
            selected={filters.device}
            handleChangeDevice={(e: string) => changeFilters("device", e)}
          />
        </div>
      )} */}

      <div className="d-flex align-items-center mt-3">
        <DateRange onChange={handleDate} />
        <Switch isShow={filters.showDate} onChange={(e: boolean) => changeFilters("showDate", e)} text="Date" />
      </div>

      {user.publisher_is_admin === 1 && (
        <div className="d-flex align-items-center mt-3">
          <Switch isShow={filters.showPublisher} onChange={(e: boolean) => changeFilters("showPublisher", e)} text="Publisher" />
          {filters.showPublisher && <SelectPublisher filters={filters.device} showGmsWithStreamer={false} selected="All" changed={(r: { label: string }) => changeFilters("publisher", r.label)} />}
        </div>
      )}
      {!user.is_gms && (
        <div className="d-flex align-items-center mt-3">
          <Switch isShow={filters.showCountry} onChange={(e: boolean) => changeFilters("showCountry", e)} text="Country" />
          {filters.showCountry && (
            <ChangeCountry
              selected={filters.country}
              handleChange={(e: any) => changeFilters("country", e.target.value)}
              style={{
                width: "200px",
                marginLeft: "10px",
              }}
            />
          )}
        </div>
      )}

      {user.publisher_is_admin === 1 && (
        <div className="d-flex align-items-center mt-3">
          <Switch isShow={filters.showPrice} onChange={(e: boolean) => changeFilters("showPrice", e)} text={isMobile ? "Price" : "Price"} />
        </div>
      )}

      <div className="ml-3 mt-3">
        <Button color="primary" size="md" onClick={() => onFilterChange(filters)}>
          <i className="fas fa-search" style={{ fontSize: 22 }}></i>
        </Button>
      </div>
    </div>
  );
};

export default DailyUsersFilters;
