import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
const ShowCountry = (props: {
  value: string | number;
  onChange: Function;
  placeholder?: string;
}) => {
  let { value, onChange, placeholder } = props;
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  return (
    <Input
      value={val}
      placeholder={placeholder}
      style={{ backgroundColor: "white", color: "black" }}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default ShowCountry;
