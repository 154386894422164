import $axios from "./../utils/axios";

function getActivity(state) {
  return $axios.get("usages/activities", {
    params: {
      fromDate: state.fromDate,
      toDate: state.toDate,
      user: state.publisher_name,
    },
  });
}

function getGBdata(state) {
  return $axios.get("gbdata", {
    params: {
      fromDate: state.fromDate,
      toDate: state.toDate,
    },
  });
}

export const UsageService = {
  getActivity,
  getGBdata,
};
