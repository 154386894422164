import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { impersonate } from "utils/heplers";
const PublisherName = (props) => {
  const { name, block = 0 } = props;
  const user = useSelector((state) => state.userReducer.user);

  return (
    <div className="impersonateContainer d-flex align-items-center">
      <div className="showOnHover">
        {user.publisher_is_admin ? (
          <Button color="primary" size="sm" onClick={() => impersonate(name)}>
            Impersonate
          </Button>
        ) : (
          ""
        )}
      </div>
      <span className="ml-3">{name}</span>
      {block === 1 && <span style={{ marginLeft: '10px' }}><i class="fas fa-ban"></i> </span>}
    </div>
  );
};

export default PublisherName;
