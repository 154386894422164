import React, { useEffect, useState } from "react";
import $axios from "utils/axios";
import DataTable, { createTheme } from "react-data-table-component";
import { Row, Col, Card, CardBody } from "reactstrap";

import {
  formatDateTime,
  paginationPerPage,
  themeTable,
} from "../../utils/heplers";
import ModalNinja from "components/Global/ModalNinja";
createTheme("solarized", themeTable);

const EmailList = (props: { publisherId: number }) => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    (async () => {
      let res = await $axios.get(`publishers/${props.publisherId}/emails`);
      setEmails(res.data.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let columns = [
    {
      name: "From",
      selector: "from",
      sortable: true,
    },
    {
      name: "To",
      selector: "to",
      sortable: true,
    },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
    },
    {
      name: "Sended at",
      selector: "subject",
      sortable: true,
      cell: (row: any) => (
        <div data-tag="allowRowEvents">{formatDateTime(row.date)}</div>
      ),
    },
    {
      name: "Status",
      sortable: true,
      cell: (row: any) => (
        <div>
          {row.status === "SUCCESS" ? (
            <i
              className="far fa-check-circle fa-2x"
              style={{ color: "green" }}
            ></i>
          ) : (
            <i
              className="far fa-times-circle fa-2x"
              style={{ color: "red" }}
            ></i>
          )}
        </div>
      ),
    },
    {
      name: "View",
      sortable: true,
      cell: (row: any) => (
        <>
          <ModalNinja
            buttonColor="info"
            buttonLabel="Open"
            buttonSize="sm"
            RightBtn="Close"
            isHtml={true}
            styleBody={{
              maxHeight: 550,
              overflow: "auto",
            }}
          >
            {row.content}
          </ModalNinja>
          <ModalNinja
            buttonColor="warning"
            buttonLabel="Info"
            buttonSize="sm"
            RightBtn="Close"
            isHtml={true}
            styleBody={{
              maxHeight: 550,
              overflow: "auto",
            }}
          >
            {row.info}
          </ModalNinja>
        </>
      ),
    },
  ];

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            {emails.length ? (
              <DataTable
                fixedHeader
                highlightOnHover
                columns={columns}
                data={emails}
                pagination={true}
                paginationPerPage={paginationPerPage}
                theme="solarized"
                overflowY={true}
                overflowYOffset="100%"
              />
            ) : (
              <div className="no-data">No data available</div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default EmailList;
