import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Picker from "./Picker";

import { RadialBarChart, RadialBar, Legend, Tooltip } from "recharts";

interface Ichart {
  name: string;
  installs: number;
  fill: string;
}

const ChartMyEarning = (props: any): JSX.Element => {
  const { data, numberMonth, onUpdate } = props;

  const [installs, setInstalls] = useState<Ichart[]>([]);
  const [numMonth, setNumMonth] = useState<number>(numberMonth);

  const selectedPublisher = useSelector(
    (state) => state.publisherReducer.selectedPublisher
  );

  useEffect(() => {
    onUpdate(numMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numMonth]);

  useEffect(() => {
    (async function () {
      if (data) {
        setInstalls(data);
        let dataChart = Object.keys(data).map((e) => {
          return {
            name: e,
            installs: data[e].total,
            fill: data[e].color,
          };
        });

        setInstalls(dataChart);
        setNumMonth(numberMonth);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher.row_id, data]);

  // if (!installs || !installs.length) {
  //   return "";
  // }

  return (
    <>
      <Picker
        label="Geographical Distribution"
        defaultSelected={numMonth}
        onSelected={(d) => {
          setNumMonth(d);
        }}
      />
      <RadialBarChart
        width={730}
        height={250}
        innerRadius="10%"
        outerRadius="80%"
        data={installs}
        startAngle={180}
        endAngle={0}
      >
        <RadialBar
          // minAngle={15}
          label={{ fill: "#666", position: "insideStart" }}
          background
          name="Total"
          // clockWise={true}
          dataKey="installs"
        />
        <Legend
          iconSize={10}
          width={150}
          height={140}
          layout="vertical"
          verticalAlign="middle"
          align="right"
        />
        <Tooltip formatter={(v: number) => v.toLocaleString()} />
      </RadialBarChart>
    </>
  );
};

export default ChartMyEarning;
