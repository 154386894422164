import React from "react";
import { UncontrolledAlert } from "reactstrap";

const InfoWrapper = (props) => {
  return (
    <UncontrolledAlert color="info">
      <i
        style={{
          display: "block",
          position: "absolute",
          left: "5px",
          top: "8px",
        }}
        className="fas fa-info-circle"
      ></i>
      {props.children}
    </UncontrolledAlert>
  );
};

export default InfoWrapper;
