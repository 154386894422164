import React, { useEffect, useState } from "react";
import { lastSevenDays } from "../utils/heplers";
import { today, formatNumber } from "../utils/heplers";

import { Card, CardBody, CardTitle, Row, CardHeader, Col, ListGroupItem, ListGroup } from "reactstrap";

import FilterWrapper from "../components/Filters/FilterWrapper";
import "antd/dist/antd.css";
import DateRange from "components/Filters/DateRange";
import ChangeDevice from "components/Filters/ChangeDevice";

type Tpublisher = {
  publisher?: string;
  total_daily_actives?: number;
  country_code?: string;
  total_unique_client?: number;
};

const TopPublishers = (props: { data: Tpublisher[]; title: string; onUpdateDate: Function; onUpdateDevice?: Function }) => {
  const { data, title, onUpdateDate, onUpdateDevice } = props;
  const [device, setDevice] = useState("");

  const [fromDate, setFromDate] = useState(lastSevenDays());
  const [toDate, setToDate] = useState(today());

  const [results, setResults] = useState(data);

  useEffect(() => {
    if (data && data.length > 0) {
      setResults(data);
    }
  }, [data]);

  const handleDate = async (e: { start: string; end: string }) => {
    await setFromDate(e.start);
    await setToDate(e.end);

    onUpdateDate({ start: fromDate, end: toDate });
  };

  return (
    <Row className="data">
      <Col md="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>Top 10 Publishers ({title}) </div>
              </div>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <FilterWrapper>
              <DateRange onChange={handleDate} />
              <ChangeDevice
                showAll={true}
                selected={device}
                handleChangeDevice={(e: string) => {
                  if (onUpdateDevice !== undefined) {
                    onUpdateDevice(e);
                    setDevice(e);
                  }
                }}
              />
            </FilterWrapper>
            <Row className="mt-3">
              <Col xs="12" md="6" lg="4">
                {results.length > 0 ? (
                  <ListGroup style={{ marginTop: "10px" }}>
                    {results.map((r, index) => (
                      <ListGroupItem
                        key={index}
                        style={{
                          backgroundColor: "#27293d",
                          borderRight: "none",
                          borderLeft: "none",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: "#fff",
                          }}
                        >
                          <div style={{ minWidth: "40px" }}>({index + 1})</div>
                          <div style={{ minWidth: "100px" }}>{formatNumber(r.total_daily_actives ?? r.total_unique_client)}</div>
                          <div style={{ minWidth: "100px" }}>{r.publisher ?? r.country_code}</div>
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                ) : null}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TopPublishers;
