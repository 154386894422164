import React, { useState, useEffect } from "react";
import { Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import $axios from "../utils/axios";

import logo from "../assets/img/logo.png";
import { LoopCircleLoading } from "react-loadingg";
import { useParams } from "react-router-dom";

const Verify: React.FC = () => {
  localStorage.clear();
  const params = useParams<any>();
  const token = params.id;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await $axios
        .get(`auth/verify-token/${token}`)
        .then((data) => {
          setLoading(!loading);
          setErrorMessage("The email has been verified");
        })
        .catch((e) => {
          setLoading(!loading);
          setErrorMessage("The link is invalid / expired");
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <Container
        style={{
          position: "relative",
          display: "flex",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "550px",
          height: "100vh",
        }}
      >
        <Col
          style={{
            paddingTop: "30px",
            height: 500,
            paddingBottom: "30px",
            textAlign: "center",
            background: `linear-gradient(60deg, #6760f3 0, #8557ea 16.67%, #9c4de0 33.33%, #af42d4 50%, #bf35c7 66.67%, #cc26ba 83.33%, #d710ab 100%)`,
          }}
        >
          <img src={logo} alt="Logo" style={{ width: "90px" }} />
          <p className="h3 text-center" style={{ color: "white" }}>
            Verify you email address
          </p>
          <h3>{errorMessage}</h3>

          {loading && <LoopCircleLoading />}

          <div className="mt-6" style={{ marginTop: "6rem" }}>
            <Link to="/signin" style={{ color: "#fff" }}>
              Go to login <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </Col>
      </Container>
    </>
  );
};
export default Verify;
