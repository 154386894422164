const initialState = {
  publishers: {},
  publisher: {},
  addedPublisher: {},
  allpublishers: {},
  uniques: {},
  countryUniques: {},
  billingData: {},
  selectedPublisher: null,
  selectedDiscoveryPublisher: null,
  countries: [],
  streamers: [],
  mobileRevenues: [],
};
export default function PublisherReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_PUBLISHERS":
      return { ...state, publishers: action.publishers };
    case "GET_COUNTRIES":
      return { ...state, countries: action.countries };
    case "GET_MOBILE_PUBLISHERS":
      return { ...state, mobiles: action.mobiles };
    case "GET_STREMER_PUBLISHERS":
      return { ...state, streamers: action.streamers };
    case "GET_REQUESTS_REVENUES":
      return { ...state, mobileRevenues: action.mobileRevenues };
    case "GET_COUNTRY_UNIQUES":
      return { ...state, countryUniques: action.countryUniques };
    case "GET_ALLPUBLISHERS":
      return { ...state, allpublishers: action.allpublishers };
    case "GET_PUBLISHER":
      return { ...state, publisher: action.publisher };
    case "ADD_PUBLISHER":
      return { ...state, addedPublisher: action.addedPublisher };
    case "GET_EDIT_PUBLISHER":
      return { ...state, editPublisher: action.editPublisher };
    case "EDIT_PUBLISHER":
      return { ...state, publisher: action.publisher };
    case "BILLING_DATA":
      return { ...state, billingData: action.billingData };
    case "SET_SELECTED_PUBLISHER":
      return { ...state, selectedPublisher: action.publisher };
    case "UPDATE_SELECTED_PUBLISHER":
      return {
        ...state,
        selectedPublisher: { ...state.selectedPublisher, ...action.publisher },
      };
    case "SET_SELECTED_DISCOVERY_PUBLISHER":
      return { ...state, selectedDiscoveryPublisher: action.publisher };
    case "EDIT_PUBLISHER_FROM_PUBLISHERS":
      let index = state.allpublishers.findIndex((r) => {
        return r.row_id === action.publisher.row_id;
      });
      let newData = state.allpublishers;
      newData[index] = action.publisher;
      return Object.assign({}, state, {
        ...state,
        allpublishers: [...newData],
      });
    case "REMOVE_PUBLISHER_FROM_PUBLISHERS":
      let newArr = state.allpublishers.filter((r) => {
        return r.row_id !== action.id;
      });
      return Object.assign({}, state, {
        ...state,
        allpublishers: [...newArr],
      });
    default:
      return state;
  }
}
