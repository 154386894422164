import React, { useEffect, useState } from "react";
import Switch from "react-switch";

const ShowCountry = (props: {
  isShow: boolean;
  onChange: Function;
  text?: string;
  disabled?: boolean;
}) => {
  let { isShow, onChange, text, disabled = false } = props;
  const [checked, setChecked] = useState(isShow);

  useEffect(() => {
    setChecked(props.isShow);
  }, [props.isShow]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          color: "white",
          marginRight: "5px",
          marginLeft: "15px",
        }}
      >
        {text}
      </span>
      <Switch
        disabled={disabled}
        onChange={(e: any) => onChange(e)}
        checked={checked}
      />
    </div>
  );
};

export default ShowCountry;
