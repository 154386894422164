import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { percentage, formatNumber } from "../../utils/heplers";
import InfoWrapper from "../Global/InfoWrapper";

// import Select from "react-select";

const InfoBox = (props: {
  style?: object;
  data: { install: number; uninstall: number };
}) => {
  const { style, data } = props;
  const [avg, setAvg] = useState<string>("0");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let average = percentage(data.uninstall, data.install).toFixed(0);
    setAvg(average);
  }, [data]);
  return (
    <div style={style}>
      <InfoWrapper>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 10px 0 10px",
            }}
          >
            <strong>Total install: </strong>
            <Badge
              color="success"
              style={{
                marginLeft: "5px",
              }}
            >
              {formatNumber(data.install)}
            </Badge>
          </div>
          |
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 10px 0 10px",
            }}
          >
            <strong>Total uninstall: </strong>
            <Badge
              color="danger"
              style={{
                marginLeft: "5px",
              }}
            >
              {formatNumber(data.uninstall)}
            </Badge>
          </div>
          |
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 10px 0 10px",
            }}
          >
            <strong> Avg :</strong>
            <Badge
              color="dark"
              style={{
                margin: "0 5px",
              }}
            >
              {avg}%
            </Badge>
            Uninstall
          </div>
        </div>
      </InfoWrapper>
    </div>
  );
};

export default InfoBox;
