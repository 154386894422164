import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DropZone from "components/Global/DropZone";
import { toast } from "react-toastify";
import { Form, Row, Col, FormGroup, Label, Input, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import DataTable from "react-data-table-component";
import { paginationPerPage, formatNumber } from "../utils/heplers";
import Loading from "../components/Global/Loading";
import { PublisherService } from "services/PublisherService";
import ModalNinja from "components/Global/ModalNinja";
import moment from "moment";
import { CSVLink } from "react-csv";

enum Tabs {
  INVOICES = 1,
  PAYMENT_METHOD,
}
//
const MyEarnings = () => {
  const [activeTab, setActiveTab] = useState(Tabs.INVOICES);
  const [open, setOpen] = useState({ stateOpen: false });

  const [payPalmodal, setPayPalModal] = useState(false);
  const [wireModal, setWireModal] = useState(false);
  const [monthlyPayments, setMonthlyPayments] = useState<IMonthlyPayments[]>([]);
  const monthlyPaymentsFormated = new Proxy(monthlyPayments, {
    get: function (target: Object, prop: String | Symbol, receiver: any) {
      const elm: Object = (target as any)[prop as any] || {};
      if (!elm || typeof elm === "undefined") return Reflect.get.apply(null, arguments as any);
      if (typeof elm === "object") {
        const p = new Proxy(elm, {
          get: function (target: Object, prop: String | Symbol, receiver: any) {
            if (prop === "month_year") {
              const elm: any = (target as any)[prop as any];
              return moment(elm).format("YYYY-MM");
            }
            if (prop === "revenues") {
              const elm: any = (target as any)[prop as any];
              return "$" + formatNumber(elm).toString();
            }

            if (prop === "invoice_total") {
              const elm: any = (target as any)[prop as any];
              return "$" + formatNumber(elm);
            }
            return Reflect.get.apply(null, arguments as any);
          },
        });
        return p;
      }
      return elm;
    },
  });
  const [loading, setLoading] = useState(true);
  const [paymentType, setPaymentType] = useState(1);

  const [wirePaymentDetails, setWirePaymentDetails] = useState({
    account_owner: "",
    bank_details: "",
    account_number: "",
    swift_iban: "",
  });

  const [payPalAccount, setPayPalAccount] = useState("");

  const toggle = (tab: number) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const selectedPublisher = useSelector((state) => state.publisherReducer.selectedPublisher);

  useEffect(() => {
    const id = selectedPublisher.row_id;
    async function fetchData() {
      //data for payment method
      const res = await PublisherService.getPublisherPaymentData(id);
      const data = res.data.data;

      if (data) {
        setPayPalAccount(data.payPal_account);
        setWirePaymentDetails({
          account_owner: data.account_owner,
          bank_details: data.bank_details,
          account_number: data.account_number,
          swift_iban: data.swift_IBAN,
        });
        setPaymentType(parseInt(data.payment_type_active));
        if (data.payment_type_active === 1) {
          setPayPalModal(true);
        } else {
          setWireModal(true);
        }
      }

      //data for monthly payments
      const paymentsRes = await PublisherService.getPublisherMonthlyEarnings(id);
      const payments = paymentsRes.data.data;
      setMonthlyPayments(payments);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher]);

  const changePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "2") {
      setWireModal(true);
      setPayPalModal(false);
      setPaymentType(2);
    } else if (e.target.value === "1") {
      setPayPalModal(true);
      setWireModal(false);
      setPaymentType(1);
    }
  };

  const saveData = async () => {
    let res = await PublisherService.insertPaymentData({
      selectedPublisher,
      wirePaymentDetails,
      payPalAccount,
      paymentType,
    });
    if (res.data.success) {
      toast.success("The data has been updated");
    }
    payPalmodal ? setPayPalModal(true) : setWireModal(true);
  };

  const changWireTransferDetails = (field: string, val: string) => {
    let w = {
      ...wirePaymentDetails,
      [field]: val,
    };
    setWirePaymentDetails(w);
  };

  const changePayPalAccount = (val: string) => {
    setPayPalAccount(val);
  };

  const onFinishUpload = (e: IFile[], rowId: number) => {
    setOpen({ stateOpen: false });
    let index = monthlyPayments.findIndex((e: { row_id: number }) => e.row_id === rowId);
    let newArr: IMonthlyPayments[] = [...monthlyPayments];
    newArr[index].file = e[0];
    setMonthlyPayments(newArr);
  };

  const columns = [
    {
      name: "Month/Year",
      selector: "month_year",
      sortable: true,
      format: (row: IMonthlyPayments) => row.month_year,
    },
    {
      name: "Revenues",
      selector: "revenues",
      sortable: true,
      format: (row: IMonthlyPayments) => row.revenues,
    },
    {
      name: "Invoice",
      selector: "invoice_total",
      sortable: true,
      format: (row: any) => row.invoice_total,
    },
    {
      name: "Upload Invoice",
      selector: "publisher_id",
      sortable: true,
      minWidth: "320",
      cell: (row: IMonthlyPayments) => (
        <div
          style={{
            padding: "0 10px",
          }}
        >
       
              <ModalNinja buttonColor="info" buttonLabel="Upload" buttonSize="sm" open={open.stateOpen}>
                <DropZone
                  selectedPublisher={selectedPublisher}
                  accept="image/*,.pdf"
                  text="Upload Montly Invoice"
                  min="100"
                  max="100"
                  fileType={2}
                  fileId={row.row_id}
                  onFinish={(e: IFile[]) => onFinishUpload(e, row.row_id)}
                />
              </ModalNinja>
            
        
        </div>
      ),
    },
    {
      name: "Paid",
      sortable: true,
      cell: (row: IMonthlyPayments) => (
        <div>
          {row.paid ? <i title="Paid" className="far fa-check-circle fa-2x" style={{ color: "green" }}></i> : <i title="Not paid" className="far fa-times-circle fa-2x" style={{ color: "red" }}></i>}
        </div>
      ),
    },
  ];
  const [cvsExport, setCvsExport] = useState<IMonthlyPayments[]>([]);
  const onExport = () => {
    const target = JSON.stringify(monthlyPaymentsFormated);
    const copy = JSON.parse(target) as IMonthlyPayments[];
    copy.forEach((item) => {
      delete item.publisher_id;
      delete item.file;
      delete item.row_id;
      delete (item as any).accumulate_month;
    });
    setCvsExport(copy);
    console.log("🚀 ~ file: MyEarnings.tsx ~ line 249 ~ onExport ~ copy", copy);
  };

  const Export = () => {
    return (
      <>
        {cvsExport && cvsExport.length > 0 ? (
          <Button>
            <CSVLink data={cvsExport}>Download</CSVLink>
          </Button>
        ) : (
          <Button onClick={onExport}>CSV</Button>
        )}
      </>
    );
  };

  return (
    <div className="content">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === Tabs.INVOICES ? "active" : ""}
            onClick={() => {
              toggle(Tabs.INVOICES);
            }}
            style={{ color: "#fff" }}
          >
            Invoices
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === Tabs.PAYMENT_METHOD ? "active" : ""}
            onClick={() => {
              toggle(Tabs.PAYMENT_METHOD);
            }}
            style={{ color: "#fff" }}
          >
            Payment Method
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.INVOICES} style={{ marginTop: "50px" }}>
          <Loading loading={loading} />
          {monthlyPayments && monthlyPayments.length && !loading ? (
            <>
              <Export />
              <DataTable
                fixedHeader
                highlightOnHover
                columns={columns}
                data={monthlyPaymentsFormated as IMonthlyPayments[]}
                pagination={true}
                paginationPerPage={paginationPerPage}
                theme="solarized"
                overflowY={true}
                overflowYOffset="100%"
              />
            </>
          ) : (
            <div className="no-data">No data available</div>
          )}
        </TabPane>
        <TabPane tabId={Tabs.PAYMENT_METHOD} style={{ marginTop: "50px" }}>
          <div>
            <Input
              value={paymentType}
              className="input-white"
              type="select"
              onChange={changePaymentMethod}
              style={{
                width: "200px",
                marginLeft: "10px",
                // zIndex: "10",
              }}
            >
              <option disabled>Select</option>
              <option value={1}>PayPal </option>
              <option value={2}>Wire Transfer </option>
            </Input>
          </div>
          {wireModal && (
            <Form>
              <section style={{ border: "2px solid #4e504e11", padding: "10px" }}>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="account_owner">Account Owner</Label>
                      <Input
                        value={wirePaymentDetails.account_owner}
                        style={{ backgroundColor: "white", color: "black" }}
                        onChange={(e) => changWireTransferDetails("account_owner", e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </section>
              <section style={{ border: "2px solid #4e504e11", padding: "10px" }}>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="bank_details">Bank Details</Label>
                      <Input value={wirePaymentDetails.bank_details} style={{ backgroundColor: "white", color: "black" }} onChange={(e) => changWireTransferDetails("bank_details", e.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
              </section>
              <section style={{ border: "2px solid #4e504e11", padding: "10px" }}>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="account_number">Account Number</Label>
                      <Input
                        value={wirePaymentDetails.account_number}
                        type="number"
                        style={{ backgroundColor: "white", color: "black" }}
                        onChange={(e) => changWireTransferDetails("account_number", e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </section>
              <section style={{ border: "2px solid #4e504e11", padding: "10px" }}>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="swift_iban">Swift\IBAN</Label>
                      <Input
                        value={wirePaymentDetails.swift_iban}
                        type="text"
                        style={{ backgroundColor: "white", color: "black" }}
                        onChange={(e) => changWireTransferDetails("swift_iban", e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </section>
            </Form>
          )}
          {payPalmodal && (
            <Form>
              <section style={{ border: "2px solid #4e504e11", padding: "10px" }}>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="paypal_account">PayPal Account</Label>
                      <Input value={payPalAccount} style={{ backgroundColor: "white", color: "black" }} onChange={(e) => changePayPalAccount(e.target.value)} />
                    </FormGroup>
                  </Col>
                </Row>
              </section>
            </Form>
          )}
          {(payPalmodal || wireModal) && (
            <div>
              <Button color="primary" onClick={saveData}>
                Save
              </Button>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default MyEarnings;
