import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AreaChart, XAxis, YAxis, Tooltip, Area } from "recharts";
import { commarize } from "utils/heplers";
import Picker from "./Picker";

interface Ichart {
  date: string;
  total: number;
}

const ChartMyUsages = (props: any) => {
  const { activity, data, numberMonth, onUpdate } = props;

  const [uniques, setUniques] = useState<Ichart[]>([]);
  const [numMonth, setNumMonth] = useState<number>(numberMonth);

  const selectedPublisher = useSelector(
    (state) => state.publisherReducer.selectedPublisher
  );

  useEffect(() => {
    onUpdate(numMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numMonth]);

  useEffect(() => {
    (async function () {
      let dataChart;
      if (!selectedPublisher.publisher_is_streamer) {
        if (data) {
          dataChart = Object.keys(data).map((e) => {
            let t = data[e].uniques.toFixed(2);

            return {
              date: moment(e).format("YY-MM"),
              total: parseInt(t),
            };
          });
          setUniques(dataChart);
          setNumMonth(numberMonth);
        }
      } else {
        if (activity) {
          dataChart = Object.keys(activity).map((e) => {
            let t = (parseInt(activity[e].total_daily_minutes) / 60).toFixed(2);
            return {
              date: moment(e).format("YY-MM"),
              total: parseInt(t),
            };
          });
          setNumMonth(numberMonth);
          setUniques(dataChart);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPublisher.row_id,
    selectedPublisher.publisher_name,
    selectedPublisher.publisher_is_streamer,
    data,
    activity,
  ]);

  return (
    <>
      <Picker
        label="My usage"
        defaultSelected={numMonth}
        onSelected={(d) => {
          setNumMonth(d);
        }}
      />
      <AreaChart
        width={730}
        height={250}
        data={uniques}
        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorcpm" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorActivity" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" />
        <YAxis tickFormatter={(v) => commarize(v)} />
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <Tooltip
          formatter={(v: number) =>
            `${v.toLocaleString()} ${
              selectedPublisher.publisher_is_streamer
                ? " Activity hours"
                : " Uniques"
            } `
          }
        />
        <Area
          type="monotone"
          dataKey="total"
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#colorcpm)"
        />
        {/* <Area
          type="monotone"
          dataKey="Activity"
          stroke="#82ca9d"
          fillOpacity={1}
          fill="url(#colorActivity)"
        /> */}
      </AreaChart>
    </>
  );
};

export default ChartMyUsages;
