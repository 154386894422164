import React, { useEffect, useState } from "react";
import $axios from "utils/axios";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Loading from "../../components/Global/Loading";

const Questionnaire = (props: { publisher: Publisher }) => {
  const [questionnaire, setQuestionnaire] = useState({
    app_names: "",
    billing_contact: "",
    communication_method: "",
    device_type: "",
    total_installs: "",
    unique_users: "",
    link: "",
  });

  const [loading, setLoading] = useState(false);
  const [userFilled, setUserFilled] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let res = await $axios.get(`publishers/${props.publisher.row_id}/info`);
      if (res.data.data) {
        setQuestionnaire(res.data.data);
      } else {
        setUserFilled(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approve = async () => {
    setLoading(true);
    setIsApproved(true);
    let res = await $axios.post(
      `publishers/${props.publisher.row_id}/approved`
    );
    if (res.data.success) {
      toast.success("The Publisher has beed approved");
      await dispatch({
        type: "EDIT_PUBLISHER_FROM_PUBLISHERS",
        publisher: { ...props.publisher, publisher_status: 3 },
      });
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Loading loading={loading} />
            {userFilled && !loading ? (
              <Form className="mt-5">
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="app_names">
                        App Names (separated with comma)
                      </Label>
                      <Input
                        value={questionnaire.app_names}
                        disabled
                        className="input-white"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="Name">Appstore\Google Play Link</Label>
                      <Input
                        className="input-white"
                        disabled
                        value={questionnaire.link}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="Name">Number of Unique Users</Label>
                      <Input
                        type="number"
                        className="input-white"
                        disabled
                        value={questionnaire.unique_users}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="app_name">Number of Total Installs</Label>
                      <Input
                        type="number"
                        value={questionnaire.total_installs}
                        disabled
                        className="input-white"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="app_name">
                        On which Platfroms Your Apps Are Running
                      </Label>
                      <Input
                        className="input-white"
                        type="select"
                        value={questionnaire.device_type}
                        disabled
                        multiple
                      >
                        <option value={1}>Mobile Android</option>
                        <option value={2}>Mobile IOS</option>
                        <option value={3}>Desktop</option>
                        <option value={4}>Streamers\Fire TV</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="Name">Billing Contact</Label>
                      <Input
                        className="input-white"
                        disabled
                        value={questionnaire.billing_contact}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <Label for="Name">
                        Method of Communication (Skype, Phone, Email)
                      </Label>
                      <Input
                        className="input-white"
                        disabled
                        value={questionnaire.communication_method}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {!isApproved && props.publisher.publisher_status === 2 && (
                  <Button block color="info" onClick={approve}>
                    Approve
                  </Button>
                )}
              </Form>
            ) : (
              <div
                style={{
                  height: 500,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 20,
                }}
                className="c-w"
              >
                User not filled the Questionnaire
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Questionnaire;
