import moment from "moment";
import $axios from "./axios";

export const dateFormat = "YYYY-MM-DD";
export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const paginationPerPage = 30;

export function lastSevenDaysMoment() {
  return moment(new Date() - 24 * 60 * 60 * 1000 * 7);
}

export function lastSevenDays() {
  return lastSevenDaysMoment().format(dateFormat);
}
export function todayMoment() {
  return moment(Date.parse(new Date()));
}
export function today() {
  return todayMoment().format(dateFormat);
}

export function formatDate(date) {
  return moment(Date.parse(date)).format(dateFormat);
}

export function formatDateTime(date) {
  return moment(Date.parse(date)).format(dateTimeFormat);
}

export function startOfMonth(date) {
  return moment(Date.parse(date)).format("YYYY-MM-01");
}

export function formatNumber(num, toFixed = 0) {
  // if(Number.isNaN(parseInt(num)))return num;
  if (!num || num===0) {
    return num;
  }
  if (toFixed !== 0) {
    return parseFloat(num.toFixed(toFixed)).toLocaleString();
  }
  return parseInt(num).toLocaleString();
}


export function formatNumberWithoutComma(num) {
  return parseInt(num).toLocaleString();
}

export function percentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const commarize = (number, min) => {
  min = min || 1e3;
  // Alter numbers larger than 1k
  if (number >= min) {
    let units = ["k", "M", "B", "T"];
    let order = Math.floor(Math.log(number) / Math.log(1000));
    let unitname = units[order - 1];
    let num = Number((number / 1000 ** order).toFixed(2));
    // output number remainder + unitname
    return num + unitname;
  }
  // return formatted original number
  return number.toLocaleString();
};

export const impersonate = async (name) => {
  let jwtImpersonate = await $axios.get(`publishers/impersonate/${name}`);
  const token = jwtImpersonate.data.data;
  if (jwtImpersonate.data.data) {
    localStorage.setItem("impersonate", token);
    window.location.href = "/signin";
  }
};

export const exitImpersonate = async (name) => {
  localStorage.removeItem("impersonate");
  window.location.href = "/signin";
};

export const themeTable = {
  text: {
    primary: "#FFFFFF",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(0,0,0,.12)",
  },
  background: {
    default: "#27293d",
  },
  context: {
    background: "#E91E63",
    text: "#FFFFFF",
  },
  divider: {
    default: "rgba(81, 81, 81, 1)",
  },
  button: {
    default: "#FFFFFF",
    focus: "rgba(255, 255, 255, .54)",
    hover: "rgba(255, 255, 255, .12)",
    disabled: "rgba(255, 255, 255, .18)",
  },
  sortFocus: {
    default: "rgba(255, 255, 255, .54)",
  },
  selected: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  highlightOnHover: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  striped: {
    default: "rgba(0, 0, 0, .87)",
    text: "#FFFFFF",
  },
};
