import { LoopCircleLoading } from "react-loadingg";
import React, { useEffect, useState } from "react";

const LoadingAnimation = (props) => {
  let { loading, height = true } = props;
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: height === true ? "calc(100vh - 200px)" : height + "px",
            width: "100%",
          }}
        >
          <LoopCircleLoading />
        </div>
      )}
    </>
  );
};

export default LoadingAnimation;
