import React from "react";
import Iframe from "react-iframe";

const Graphs = () => {
  return (
    <section
      style={{ marginLeft: "270px", marginTop: "20px", height: "100vh" }}
    >
      <Iframe
        url="http://ninja0.ninjatech.io/graphs_all.html"
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        // display="initial"
        position="relative"
      />
    </section>
  );
};

export default Graphs;
