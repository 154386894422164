import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { useParams } from "react-router-dom";
import EmailList from "components/Publisher/EmailsList";
import Questionnaire from "components/Publisher/Questionnaire";
import PublisherProfile from "components/Publisher/PublisherProfile";
import { useSelector } from "react-redux";

enum Tabs {
  PUBLISHER = 1,
  EMAILS,
  QUESTIONNAIRE,
}

const Publisher = () => {
  const [activeTab, setActiveTab] = useState(Tabs.PUBLISHER);
  const params = useParams<{ id: string }>();
  const publisherId: number = parseInt(params.id);

  const publishers = useSelector(
    (state) => state.publisherReducer.allpublishers
  );

  const publisher = publishers.filter(
    (p: Publisher) => p.row_id === publisherId
  )[0];

  const toggle = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="content">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === Tabs.PUBLISHER ? "active" : ""}
            onClick={() => {
              toggle(Tabs.PUBLISHER);
            }}
            style={{ color: "#fff" }}
          >
            Publisher
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === Tabs.EMAILS ? "active" : ""}
            onClick={() => {
              toggle(Tabs.EMAILS);
            }}
            style={{ color: "#fff" }}
          >
            Emails
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === Tabs.QUESTIONNAIRE ? "active" : ""}
            onClick={() => {
              toggle(Tabs.QUESTIONNAIRE);
            }}
            style={{ color: "#fff" }}
          >
            Questionnaire
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.PUBLISHER}>
          <PublisherProfile publisherId={publisherId} />
        </TabPane>
        <TabPane tabId={Tabs.EMAILS}>
          <EmailList publisherId={publisherId} />
        </TabPane>
        <TabPane tabId={Tabs.QUESTIONNAIRE}>
          <Questionnaire publisher={publisher} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Publisher;
