import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NewEditPublisher } from "actions/PublisherActions";
import ChangeCountry from "components/Filters/ChangeCountry";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";

const UpdateProfile = (props) => {
  const { user } = props;
  const [publisher, setPublisher] = useState(user);

  const dispatch = useDispatch();

  const changeProfile = (field, val) => {
    let p = {
      ...publisher,
      [field]: val,
    };
    setPublisher(p);
  };

  const save = async () => {
    await dispatch(NewEditPublisher(publisher));
  };
  return (
    <>
      <div className="content">
        <Col>
          <Row>
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="Name">Name</Label>
                        <Input
                          className="disabled"
                          readOnly
                          style={{ backgroundColor: "white", color: "black" }}
                          value={publisher.publisher_name}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="publisher_username">Username</Label>
                        <Input
                          value={publisher.publisher_username}
                          style={{ backgroundColor: "white", color: "black" }}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="publisher_contact_email">
                          Email address
                        </Label>
                        <Input
                          style={{ backgroundColor: "white", color: "black" }}
                          value={publisher.publisher_contact_email}
                          onChange={(e) =>
                            changeProfile(
                              "publisher_contact_email",
                              e.target.value
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="publisher_company_name">Company Name</Label>
                        <Input
                          value={publisher.publisher_company_name}
                          style={{ backgroundColor: "white", color: "black" }}
                          onChange={(e) =>
                            changeProfile(
                              "publisher_company_name",
                              e.target.value
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="country">Country</Label>
                        <ChangeCountry
                          selected={publisher.publisher_country}
                          handleChange={(e) =>
                            changeProfile("publisher_country", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="city">City</Label>
                        <Input
                          value={publisher.city ?? ""}
                          style={{ backgroundColor: "white", color: "black" }}
                          onChange={(e) =>
                            changeProfile("city", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label for="street">Street</Label>
                        <Input
                          style={{ backgroundColor: "white", color: "black" }}
                          value={publisher.street ?? ""}
                          onChange={(e) =>
                            changeProfile("street", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  onClick={save}
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  // disabled={isValid}
                >
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default UpdateProfile;
