import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import TopPublishers from "./TopPublishers";
import { PublisherService } from "../services/PublisherService";
import { today, lastSevenDays } from "../utils/heplers";
import DailyUsers from "./DailyUsers";

enum Tabs {
  DATA = 1,
  TOP_ACTIVE,
  TOP_COUNTRIES,
}

const DailyUsersAdmin = () => {
  const [activeTab, setActiveTab] = useState(Tabs.DATA);

  const [dateCountries, setDateCountries] = useState({
    start: today(),
    end: lastSevenDays(),
  });
  const [device, setDevice] = useState("all");
  const [topCountries, setTopCountries] = useState([]);

  const [dateActives, setDateActives] = useState({
    start: today(),
    end: lastSevenDays(),
  });
  const [topActives, setTopActives] = useState([]);

  const toggle = (tab: number) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getTopCountries = async () => {
    let res: any = await PublisherService.getTopPublishersCountries(
      dateCountries.start,
      dateCountries.end,
      device
    );
    setTopCountries(res.data.data);
  };

  const getTopActives = async () => {
    let res: any = await PublisherService.getTopPublishersActives(
      dateActives.start,
      dateActives.end,
      device
    );
    setTopActives(res.data.data);
  };

  useEffect(() => {
    getTopCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateCountries, device]);

  useEffect(() => {
    getTopActives();
    // console.log(device);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateActives, device]);

  return (
    <div className="content">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === Tabs.DATA ? "active" : ""}
            onClick={() => {
              toggle(Tabs.DATA);
            }}
            style={{ color: "#fff" }}
          >
            Data
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === Tabs.TOP_ACTIVE ? "active" : ""}
            onClick={() => {
              toggle(Tabs.TOP_ACTIVE);
            }}
            style={{ color: "#fff" }}
          >
            Top 10 Publishers (Active Users)
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === Tabs.TOP_COUNTRIES ? "active" : ""}
            onClick={() => {
              toggle(Tabs.TOP_COUNTRIES);
            }}
            style={{ color: "#fff" }}
          >
            Top 10 Publishers (Countries)
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.DATA}>
          <DailyUsers />
        </TabPane>
        <TabPane tabId={Tabs.TOP_ACTIVE}>
          <TopPublishers
            data={topActives}
            onUpdateDate={(e: { start: string; end: string }) => {
              setDateActives({
                start: e.start,
                end: e.end,
              });
            }}
            title={"Active Users"}
            onUpdateDevice={(e: string) => {
              setDevice(e);
            }}
          />
        </TabPane>
        <TabPane tabId={Tabs.TOP_COUNTRIES}>
          <TopPublishers
            data={topCountries}
            onUpdateDate={(e: { start: string; end: string }) => {
              setDateCountries({
                start: e.start,
                end: e.end,
              });
            }}
            title={"Countries"}
            onUpdateDevice={(e: string) => {
              setDevice(e);
            }}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default DailyUsersAdmin;
