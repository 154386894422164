import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Label } from "reactstrap";

const Picker = (props: {
  label: string;
  defaultSelected: number;
  onSelected?: (v: number) => void;
}) => {
  const { label, onSelected, defaultSelected = 3 } = props;
  const [selected, setSelected] = useState<number>(defaultSelected);

  useEffect(() => {
    if (onSelected) {
      onSelected(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <Label className="text-center" style={{ width: "100%" }}>
      <div className="d-flex justify-content-center">
        <div className="ml-auto">{label}</div>
        <div className="ml-auto">
          <ButtonGroup>
            <Button
              color="primary"
              size="sm"
              onClick={() => setSelected(3)}
              active={selected === 3}
            >
              3M
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => setSelected(6)}
              active={selected === 6}
            >
              6M
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => setSelected(12)}
              active={selected === 12}
            >
              1Y
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Label>
  );
};

export default Picker;
