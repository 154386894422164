const initialState = {
  users: {},
  installs: {},
  all_installs: {},
  countries: {},
  token: null,
  user: {},
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_USERS":
      return { ...state, users: action.users };
    case "GET_INSTALLS":
      return { ...state, installs: action.installs };
    case "GET_ALL_INSTALLS":
      return { ...state, all_installs: action.all_installs };
    case "GET_COUNTRIES":
      return { ...state, countries: action.countries };
    case "SET_TOKEN":
      return { ...state, token: action.token };
    case "SET_USER":
      return { ...state, user: action.user };
    default:
      return state;
  }
}
