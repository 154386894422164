import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";

const DropZone = (props) => {
  const {
    selectedPublisher,
    accept,
    min,
    max,
    fileType,
    fileId,
    onFinish = false,
    text = "",
    multiple = false,
  } = props;

  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("files", file);
    body.append("publisher", selectedPublisher.row_id);
    body.append("fileType", fileType);
    body.append("ref_id", fileId);
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    return {
      url: process.env.REACT_APP_API_URL + "publishers/upload",
      method: "POST",
      body: body,
      headers: headers,
    };
  };

  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      toast.success("The file has been uploaded");
      onFinish(response.data);
    }
  };

  //   const handleChangeStatus = ({ meta, remove }, status) => {
  //     if (status === "headers_received") {
  //       onFinish();
  //     } else if (status === "aborted") {
  //     }
  //   };

  //   const handleSubmit = async (files, allFiles) => {
  //     allFiles.forEach((f) => f.remove());
  //     await PublisherService.uploadFile(
  //       files,
  //       selectedPublisher,
  //       fileType,
  //       fileId
  //     );
  //     if (onFinish) {
  //       onFinish();
  //     }
  //   };

  return (
    <div>
      <Dropzone
        getUploadParams={getUploadParams}
        // handleSubmit={handleSubmit}
        onChangeStatus={handleChangeStatus}
        accept={accept}
        maxFiles={1}
        // onSubmit={handleSubmit}
        styles={{
          dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
          dropzone: { minHeight: min, maxHeight: max, overflow: "hidden" },
        }}
        multiple={multiple}
        canCancel={false}
        inputContent={text}
      />
    </div>
  );
};

export default DropZone;
