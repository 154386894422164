import React, { useEffect, useState } from "react";
import $axios from "utils/axios";
import DataTable, { createTheme } from "react-data-table-component";
import { themeTable, paginationPerPage } from "../../utils/heplers";
import Loading from "../Global/Loading";
import ShowCountry from "components/Global/ShowCountry";
import { useSelector } from "react-redux";
import PublisherName from "components/Global/PublisherName";
import CsvGenerator, { DataTableFormatData } from "components/csvGenerator";
createTheme("solarized", themeTable);

const Mobiles = (props: { filters: any }) => {
  const { filters } = props;
  let url = { ...filters };
  url.device = filters.device === "mobile" ? 0 : 1;
  const filtersUri = new URLSearchParams(url).toString();
  const user = useSelector((state) => state.userReducer.user);

  let [data, setData] = useState([]);
  let [cols, setCols] = useState([]);
  const [loading, setLoading] = useState(false);
  let [proxy, setProxy] = useState([]);
  let [colsDict, setColsDict] = useState([]);

  useEffect(() => {
    if (filters.fromDate) {
      (async function () {
        setLoading(true);
        let res = await $axios.get("publishers/daily/users?" + filtersUri);

        if (res.data.success) {
          let datas = [];
          Object.keys(res.data.data.rows).forEach((d) => {
            if (res.data.data.rows[d] != null) {
              datas.push(res.data.data.rows[d]);
            }
          });
          setData(res.data.data);
          let columns: ((prevState: never[]) => never[]) | any = [];

          Object.keys(res.data.data.columns).forEach((e) => {
            if (res.data.data.columns[e].type === "number") {
              columns.push({
                name: res.data.data.columns[e].name,
                selector: e,
                sortable: true,
                // cell: (row: any) => formatNumber(row[e]),
              });
            } else if (res.data.data.columns[e].type === "date") {
              columns.push({
                name: res.data.data.columns[e].name,
                selector: e,
                sortable: true,
                // cell: (row: any) => formatDate(row[e]),
              });
            } else if (res.data.data.columns[e].type === "country") {
              columns.push({
                name: res.data.data.columns[e].name,
                selector: e,
                sortable: true,
                cell: (row: any) => <ShowCountry country_code={row[e]} />,
              });
            } else if (res.data.data.columns[e].type === "money") {
              columns.push({
                name: res.data.data.columns[e].name,
                selector: e,
                sortable: true,
                // cell: (row: any) => `$ ${formatNumber(row[e], 0)}`,
              });
            } else if (res.data.data.columns[e].type === "publisherName") {
              if (user.publisher_is_admin === 1) {
                columns.push({
                  name: res.data.data.columns[e].name,
                  selector: e,
                  sortable: true,
                  grow: user.publisher_is_admin ? 2 : 1,
                  cell: (row: any) => <PublisherName name={row[e]} />,
                });
              }
            } else if (res.data.data.columns[e].type === "country_code" && user.publisher_is_admin) {
              columns.push({
                name: res.data.data.columns[e].name,
                selector: e,
                sortable: true,
                cell: (row: any) => <ShowCountry country_code={row[e]} />,
              });
            } else if (res.data.data.columns[e].type === "pulls") {
              if (user.publisher_is_admin === 1) {
                columns.push({
                  name: res.data.data.columns[e].name,
                  selector: e,
                  sortable: true,
                  // cell: (row: any) => formatNumber(row[e]),
                });
              }
            } else {
              columns.push({
                name: res.data.data.columns[e].name,
                selector: e,
                sortable: true,
                // cell: (row) => row,
              });
            }
          });
          setCols(columns);
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  useEffect(() => {
    const m = cols.map((i) => {
      return { colName: i.selector, displayName: i.name };
    });
    setColsDict(m);
  }, [cols]);
  return (
    <div>
      <Loading loading={loading} />
      {cols.length > 0 && (
        <>
          <CsvGenerator whiteList={colsDict} data={data as unknown as DataTableFormatData} setProxy={setProxy} />
          <DataTable
            fixedHeader
            highlightOnHover
            columns={cols}
            data={proxy}
            pagination={true}
            paginationPerPage={paginationPerPage}
            theme="solarized"
            defaultSortField="revenue"
            defaultSortAsc={false}
            overflowY={true}
            overflowYOffset="100%"
          />
        </>
      )}
    </div>
  );
};

export default Mobiles;
